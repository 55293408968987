import { createSvgIcon } from '@mui/material/utils';

export const ReceiptTax = createSvgIcon(
  <svg viewBox="0 0 18 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.0001 0.400024C2.36358 0.400024 1.75313 0.652881 1.30304 1.10297C0.852954 1.55306 0.600098 2.1635 0.600098 2.80002V19.6L4.8001 17.2L9.0001 19.6L13.2001 17.2L17.4001 19.6V2.80002C17.4001 2.1635 17.1472 1.55306 16.6972 1.10297C16.2471 0.652881 15.6366 0.400024 15.0001 0.400024H3.0001ZM6.0001 4.00002C5.52271 4.00002 5.06487 4.18967 4.72731 4.52723C4.38974 4.8648 4.2001 5.32263 4.2001 5.80002C4.2001 6.27741 4.38974 6.73525 4.72731 7.07282C5.06487 7.41038 5.52271 7.60002 6.0001 7.60002C6.47749 7.60002 6.93532 7.41038 7.27289 7.07282C7.61046 6.73525 7.8001 6.27741 7.8001 5.80002C7.8001 5.32263 7.61046 4.8648 7.27289 4.52723C6.93532 4.18967 6.47749 4.00002 6.0001 4.00002ZM13.4485 4.35162C13.2235 4.12666 12.9183 4.00028 12.6001 4.00028C12.2819 4.00028 11.9767 4.12666 11.7517 4.35162L4.5517 11.5516C4.43709 11.6623 4.34567 11.7947 4.28278 11.9411C4.21989 12.0875 4.18678 12.245 4.1854 12.4043C4.18401 12.5637 4.21437 12.7217 4.27471 12.8692C4.33505 13.0166 4.42415 13.1506 4.53682 13.2633C4.64949 13.376 4.78348 13.4651 4.93095 13.5254C5.07843 13.5857 5.23644 13.6161 5.39578 13.6147C5.55511 13.6133 5.71258 13.5802 5.85898 13.5173C6.00539 13.4545 6.1378 13.363 6.2485 13.2484L13.4485 6.04842C13.6735 5.82339 13.7998 5.51822 13.7998 5.20002C13.7998 4.88183 13.6735 4.57666 13.4485 4.35162ZM12.0001 10C11.5227 10 11.0649 10.1897 10.7273 10.5272C10.3897 10.8648 10.2001 11.3226 10.2001 11.8C10.2001 12.2774 10.3897 12.7353 10.7273 13.0728C11.0649 13.4104 11.5227 13.6 12.0001 13.6C12.4775 13.6 12.9353 13.4104 13.2729 13.0728C13.6105 12.7353 13.8001 12.2774 13.8001 11.8C13.8001 11.3226 13.6105 10.8648 13.2729 10.5272C12.9353 10.1897 12.4775 10 12.0001 10Z"
    />
  </svg>, 'ReceiptTax');
